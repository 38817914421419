<template>
  <div>
    <h3 ref="intro">
      Circle
    </h3>
    <p>
      A circle is a planar shape obtained by connecting all the points on a plane that are at the same distance from a given point called the center.
    </p>
    <h3 ref="types">
      Features of a Circle
    </h3>
    <ul class="a">
      <li>
        <h5> Radius </h5>
        The distance between any point on the circle and its center is called the radius of the circle. The radius is commonly denoted as <i>R</i>.
      </li>
      <li>
        <h5> Circumference </h5>
        If you walk along the periphery of the circle, the distance traveled is called circumference of the circle. The circumference of a circle is related to its radius:
        $$L = 2\pi R$$
      </li>
      <li>
        <h5> Area </h5>
        The area of a circle is related to its radius as:
        $$A = \pi R^2$$
      </li>
    </ul>
    <br>
    <h3 ref="pg">
      MagicGraph | Drawing a Circle
    </h3>
    <p>
      This MagicGraph offers a visually interactive way to create a circle using two points. Select any two points on the graph. Point '1' is the center
      of the circle. Point '2' lies on the circle.
    </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
import Interval from '../../../common/interval';
import randomNumberInRange from '../../../common/randomNumberInRange';
export default {
  name: 'Circles',
  data:()=>({
  }),
  computed: {
  component() {
    return this.test ? "vue-ellipse-progress-test" : "vue-ellipse-progress";
  },
  },
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Drawing a Circle ';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is a Circle?', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Features of a Circle', img:'/assets/number-2.svg', action: () => this.goto('types')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    this.$store.commit('navigation/toggleshowhome', false);
    this.$store.commit('navigation/replaceMath', true);
    this.$store.commit('navigation/replaceLeftArrow', true);
    this.$store.commit('navigation/replaceModule', true);
    this.$store.commit('navigation/replaceSample', true);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
    Interval.addTask(this.randomizeOptions);
  },
  methods:{
  randomizeOptions() {
      this.progress = randomNumberInRange(0, 100);
    }
    },
  metaInfo() {
  return{ title: 'Draw a Circle',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about Thales Theorem'}
                ]
        }
   },
}
</script>

<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
.icn{
color: var(--v-red-base);;
}
</style>
